import React from "react"
import browserLang from "browser-lang"
import IntlContext from "./intl-context";

const languages = ['en', 'nl'];
const defaultLanguage = ['en'];

const localizedPathExp = /\/([a-z]{2})(\/.+)?/;

const withIntlProvider = (intl) => children => {
  return (
    <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>
  )
}

export default ({ element, props }) => {
  if (!props) {
    return
  }

  const { pageContext } = props;
  const { mdPageLocale, intl } = pageContext;

  // If a URL is shaped like /nl/whatever or /en/whatever, then use locale specified in URL.
  const [,urlPageLocale] = props.path?.match(localizedPathExp) || [];

  if (typeof window !== "undefined") {
    // locale from URL has highest prio, followed by locale stored in localStorage and finally the browser preference.
    let detected =
      urlPageLocale || 
      window.localStorage.getItem("gatsby__intl-locale") ||
      browserLang({
        languages,
        fallback: defaultLanguage,
      })

    if (!languages.includes(detected)) {
      detected = mdPageLocale
    }

    window.___gatsbyIntl = intl;
    if(intl && intl.locale !== detected) {
      window.localStorage.setItem("gatsby__intl-locale", detected);
      const newUrl = intl.currentPageLinks[detected];
      window.location.replace(newUrl);
    }

  }

  return withIntlProvider(intl)(element);
}